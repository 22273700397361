$primary: #77bd7e;
$secondary: #007cb9;
$inverse: #fff;
$BLK: #000;

img {
  max-width: 100%;
  height: auto;
}

ul {
  padding: 0;
  @media screen and (max-width: 500px) {
    padding-left: 16px;
  }
}

a.logo-link {
  flex-grow: 1;
  margin-left: 16px;
}

div.header-desktop {
  padding-top: 15px;
  padding-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;
  min-height: auto;
}

.app-container a {
  color: $primary;
  font-weight: 700;
  border-right: 1px solid $primary;
  padding: 5px 15px;
  &:last-child {
    border-right: none;
  }
}

.nav-items {
  display: flex;
  justify-content: center;
  background: $secondary;
}

p.copyright {
  color: $secondary;
}

div.banner-title {
  color: $primary;
  > h1 {
    font-size: 80px;
    margin-top: 15px;
    margin-bottom: 15px;
    @media screen and (max-width: 1600px) {
      font-size: 60px;
    }
    @media screen and (max-width: 1120px) {
      font-size: 30px;
    }
    @media screen and (max-width: 468px) {
      text-align: center;
    }
  }
  > span {
    font-size: 36px;
    color: $BLK;
    @media screen and (max-width: 1600px) {
      font-size: 42px;
    }
    @media screen and (max-width: 1120px) {
      font-size: 22px;
    }
    @media screen and (max-width: 468px) {
      text-align: center;
      display: block;
    }
  }
}

@media screen and (max-width: 991px) {
  header.MuiAppBar-colorPrimary {
    height: auto;
    left: auto;
  }
}

.banner-warpper {
  position: relative;
  > img {
    @media screen and (max-width: 468px) {
      margin-top: 50px;
    }
  }
}

.banner-title {
  position: absolute;
  top: 100px;
  left: 50%;
  @media screen and (max-width: 1600px) {
    top: 50px;
  }
  @media screen and (max-width: 768px) {
    top: 50px;
  }
  @media screen and (max-width: 468px) {
    position: relative;
    left: auto;
    padding-left: 15px;
    top: auto;
  }
}

.address span {
  display: block;
  &:first-child {
    font-weight: 700;
  }
}

div.address {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

input.MuiOutlinedInput-input {
  padding: 10px 14px;
}

div.adr {
  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
  }
}

#blog svg.MuiSvgIcon-root {
  font-size: 6rem;
  color: $primary;
}

button.MuiButton-root,
div.MuiOutlinedInput-root {
  border-radius: 0;
  height: 40px;
  font-size: 1rem;
}

button.slick-prev,
button.slick-next {
  width: auto;
  height: auto;
  &::before {
    font-size: 4rem;
    color: $BLK;
    width: 40px;
    height: 40px;
  }
}

button.slick-next {
  right: 0;
  z-index: 999;
  @media (min-width: 478px) and (max-width: 991px) {
    right: 0px;
  }
}

button.slick-prev {
  left: 0px;
  z-index: 999;
}

div.slick-track > div {
  position: relative;
  > div {
    padding: 20px;
    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }
}

.slick-slide img {
  max-width: 2400px;
  margin: 0 auto;
}

.css-1thdg65-ErrorContainer {
  display: none;
}

.menu-item {
  color: #000;
  text-decoration: none;
  &.active {
    color: $primary;
    font-weight: 600;
  }
  &.flow {
    flex-grow: 1;
  }
}

img.logo {
  width: 150px;
  padding-right: 15px;
  border-right: 1px solid #e9e9e9;
  &-mobile {
    width: 110px;
    padding: 10px 15px;
  }
}

div.header-desktop {
  @media screen and (max-width: 991px) {
    display: none;
  }
}

.header-mobile {
  display: flex;
  margin-bottom: -3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;

  @media screen and (min-width: 991px) {
    display: none !important;
  }
}

div.loader-wrapper {
  position: relative;
  top: -50px;
  > div.loader {
    color: $primary;
    position: absolute;
    left: 50%;
  }
}

p.text {
  color: #333;
}

div.name {
  display: flex;
}

div#menuButton {
  margin-right: 16px;
  color: #fff;
  position: relative;
  text-align: center;
  outline: none;
  img {
    width: 90%;
    max-width: 100%;
    border-radius: 10px;
  }
  h3 {
    position: absolute;
    bottom: 15%;
    padding: 0 30px;
    left: 0;
    right: 0;
  }
  svg {
    position: relative;
    bottom: 30px;
    visibility: hidden;
    left: 0;
    color: $primary;
    right: 0;
  }
  &:hover {
    transform: scale(1.05);
    outline: none;
    img {
      border: 2px solid $primary;
      border-top: 9px solid $primary;
    }
    h3 {
      color: $primary;
    }
    svg {
      visibility: visible;
    }
  }
  &.not_active {
    outline: none;
    img {
      position: relative;
      border: 0;
    }
    &:hover {
      transform: none;
      h3 {
        color: $secondary;
      }
      svg {
        visibility: hidden;
      }
    }
  }
  &.is_active {
    outline: none;
    img {
      position: relative;
      border: 0;
    }
    &::after {
      content: "";
      position: absolute;
      left: 42%;
      width: 0;
      height: 0;
      border-left: 30px solid transparent;
      border-right: 30px solid transparent;
      border-top: 30px solid #fff;
      clear: both;
    }
    &:hover {
      transform: none;
      h3 {
        color: $secondary;
      }
      svg {
        visibility: hidden;
      }
    }
  }
}

.car-title {
  text-align: center;
}

.login-buttons {
  display: flex;

  svg {
    color: $primary;
  }
  a {
    padding: 0;
    &:hover {
      text-decoration: none;
      background-color: transparent;
    }
  }
  button {
    padding: 0;
    &:hover {
      background-color: transparent;
    }
  }
}

#menu-appbar {
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

p.no-courses {
  color: $primary;
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

div.slider-container {
  margin-left: 22px;
  margin-right: 22px;
  margin-top: 44px;
}

a.menu-item {
  margin-right: 5px;
  &.with-border {
    border-right: 3px solid $primary;
    padding-right: 15px;
  }

  &:hover {
    text-decoration: none;
    color: $primary;
    font-weight: 700;
  }
  &.menu-item-button {
    &:hover {
      text-decoration: none;
      color: $primary;
    }
  }
}

div.filter-result {
  background-color: #0e0502;
  color: $secondary;
  padding: 15px;
  margin-top: -28px;

  padding-top: 40px;
  span {
    display: block;
    margin-bottom: 15px;
  }
  @media screen and (max-width: 1100px) {
    margin-left: 18px;
    margin-right: 18px;
  }
  @media screen and (max-width: 767px) {
    margin-left: 15px;
    margin-right: 15px;
  }
}

div.course_desc {
  display: none;
  &:nth-of-type(1) {
    display: block;
  }
}

div.filter-cards {
  div.slick-track {
    margin-left: 0;
    margin-right: 0;
  }
}

svg.close_button {
  float: right;
  position: relative;
  top: -30px;
}

a.account-icon {
  padding: 5px;
  svg {
    font-size: 2rem;
  }
}

#onlineCourse {
  margin-bottom: 30px;
  margin-left: 150px;
  @media screen and (max-width: 991px) {
    margin-left: 0;
  }
}

div#simple-menu div.MuiPopover-paper {
  width: 100%;
  left: 0 !important;
  max-width: 100%;
  top: 16px !important;
}

div#simple-menu {
  top: 52px !important;

  ul {
    padding: 0;
  }
  li {
    border-bottom: 1px solid #e7e7e7;
    padding: 12px 20px;
  }

  a.menu-item.menu-item-button {
    color: $primary;
    font-weight: 600;

    &::after {
      content: "\00BB";
      padding-left: 4px;
    }
  }
}

div.header-mobile {
  .logo-link-mobile {
    margin-left: 50%;
    img {
      margin-left: -50%;
    }
  }
  button#burger-menu:not(#modal-button) {
    position: absolute;
    top: 0px;
    svg {
      font-size: 3rem;
      width: 0.9em;
      height: 0.9em;
    }
  }
}

#menu-appbar {
  div.MuiPopover-paper {
    top: 40px !important;
  }
  ul {
    padding: 0;
    border: 1px solid #313131;
    background-color: #fff;
    color: #313131;
  }
  li {
    padding: 0;
    border-bottom: 1px solid #e7e7e7;
  }
  a {
    font-weight: 400;
    padding: 10px 15px;
    display: inline-block;
    width: 100%;
    color: #313131;
  }
}

a:not(.btn).link-card:hover {
  text-decoration: none;
}

.text + svg {
  padding-left: 5px;
  @media screen and (max-width: 991px) {
    padding-left: 0;
  }
}

#menu-chatbot {
  div.MuiPaper-root {
    width: 300px;
    border-radius: 0;
    margin-top: 45px;
  }
  ul {
    padding: 0;
  }
}

.header-modal {
  width: 44%;
  @media screen and (max-width: 900px) {
    width: auto;
  }
}

.modal-body-header {
  width: 300px;
  z-index: 100;
  background-color: $inverse;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 478px) {
    width: auto;
  }
  header {
    width: 100%;
    background-color: $primary;
    color: $inverse;
    text-align: center;
    height: 30px;
    padding-top: 10px;
  }
  div {
    padding: 20px;
    label {
      padding-top: 10px;
      display: block;
    }
    input,
    textarea {
      margin-bottom: 15px;
      width: 100%;
    }
    input.submit-button {
      background-color: $primary;
      color: $inverse;
      width: 100%;
      background-image: none;
      box-shadow: none;
      text-shadow: none;
      border-radius: 0;
      border: 1px solid $primary;
      font-size: 1rem;
      letter-spacing: 1px;
      padding: 4px 10px;
      height: 44px;
      vertical-align: top;
      -webkit-font-smoothing: antialiased;
      &:hover,
      &:focus,
      &:active {
        background-color: $inverse;
        color: $primary;
        background-image: none;
        border: 1px solid $primary;
        box-shadow: none;
        text-shadow: none;
      }
    }
  }
}

.second-row-header {
  padding-bottom: 0;
  padding: 0 !important;
  min-height: auto !important;
  display: flex;

  button {
    background-color: $primary;
    color: $inverse;
    background-image: none;
    box-shadow: none;
    text-shadow: none;
    border-radius: 0;
    border: 1px solid $primary;
    font-size: 1rem;
    letter-spacing: 1px;
    padding: 4px 10px;
    height: 54px;
    vertical-align: top;
    min-width: auto;
    -webkit-font-smoothing: antialiased;
    @media screen and (max-width: 991px) {
      width: 39px;
    }
    &:hover,
    &:focus,
    &:active {
      background-color: $inverse;
      color: $primary;
      background-image: none;
      border: 1px solid $primary;
      box-shadow: none;
      text-shadow: none;
    }
    .text {
      @media screen and (max-width: 991px) {
        display: none;
      }
    }
  }
}

#header-tabs .tab-pane {
  padding: 15px;
  height: 80px;
}
#header-tabs .nav-tabs {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
}
#header-tabs .nav-tabs li {
  list-style: none;
  padding: 12.5px;
  flex-grow: 1;
  margin: 0px !important;
  text-align: center;
}

#header-tabs .nav-tabs li a {
  margin-right: 0px !important;
  border: none !important;
  color: #333;
  text-decoration: none;
  font-size: 1.125rem;
  @media screen and (max-width: 991px) {
    font-size: 1rem;
    padding: 0;
  }
}
#header-tabs .nav-tabs a:hover {
  border: none;
}

#header-tabs .nav-tabs li.active-item {
  border-bottom: 3px solid $primary !important;
  a.active {
    background-color: transparent !important;
    color: $primary !important;
    transition: all 0.4s linear;

    @media screen and (max-width: 991px) {
      border-bottom: none !important;
    }
  }
}

.second-row-header {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 5px 0px;
}

div.layout {
  margin-top: 148px;
  @media screen and (max-width: 1200px) {
    margin-top: 122px;
  }

  @media screen and (max-width: 450px) {
    margin-top: 145px;
  }
  @media screen and (max-width: 315px) {
    margin-top: 185px;
  }
}

div.banner {
  position: relative;
  padding: 0;
  background-color: #fff;
  flex-wrap: wrap;
  > div {
    position: absolute;
    color: #1f3685;
    font-size: 3.25rem;
    width: 600px;
    &::first-line {
      font-weight: normal;
    }
    @media screen and (max-width: 767px) {
      position: static;
      padding-top: 20px;
      color: $primary;
      font-size: 2rem;
      width: 400px;
      text-align: left;
      padding: 10px 24px;
    }
  }
  @media screen and (max-width: 767px) {
    justify-content: left;
  }
}

div.wrapper {
  margin-top: 150px;
}
div.select-input {
  float: right;
  margin-top: 20px;
  @media screen and (max-width: 1024px) {
    float: none;
    margin-right: 0;
  }
  label {
    top: -10px;
  }
  div.MuiOutlinedInput-root {
    height: auto;
    width: 300px;
  }
}

#menu- {
  .MuiPopover-paper {
    li {
      padding: 20px;
      cursor: pointer;
      svg {
        margin-top: -3px;
      }
    }
  }
}
.react-tabs {
  width: 800px;
  @media screen and (max-width: 991px) {
    width: auto;
  }
}

.sc-gsDKAQ.hiVXbI {
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  margin-right: -40px;
  @media screen and (max-width: 700px) {
    margin-right: 0;
  }
}

div.gThsXv .react-tabs__tab--selected {
  background-color: transparent !important;
  color: $primary;
  font-weight: 700;
}

div.sc-dkPtRN.khXcuW {
  padding-left: 40px;
  @media screen and (max-width: 700px) {
    padding-left: 0px;
  }
}

div.gThsXv .react-tabs__tab:hover {
  background: $primary !important;
  color: $inverse;
}

div.MuiSelect-selectMenu {
  text-align: center;
  color: $primary;
  border-radius: 0;
  font-weight: 600;
  border: 2px solid $primary;
  svg {
    color: $primary;
  }
  input {
    border: 1px solid $primary;
  }
}

.MuiInputBase-root {
  svg {
    color: $primary;
  }
}

div.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $primary;
}

h1.course-name {
  font-family: "RobotoRegular", sans-serif;
  font-size: 2.25rem;
  @media screen and (max-width: 767px) {
    font-size: 1.5rem;
  }
}

h2.course-title-card {
  font-size: 1.5rem;
  margin-bottom: 30px;
  word-break: break-word;
}

.course-content {
  min-height: 200px;

  @media screen and (max-width: 1300px) {
    min-height: 250px;
  }
  @media screen and (max-width: 1024px) {
    min-height: 320px;
  }
  @media screen and (max-width: 600px) {
    min-height: 175px;
  }
}

button.slick-prev::before,
button.slick-next::before {
  color: #313131;
}

.react-tabs > div {
  @media screen and (max-width: 600px) {
    display: block;
  }
}

div.second-row-header {
  align-items: unset;
}

div#header-tabs {
  margin-top: 5px;
  @media screen and (max-width: 991px) {
    margin-top: 8px;
  }
}

.course-description {
  line-height: 1.5;
  font-size: 0.875rem;
}

.hamburger-menu {
  left: 20px;
  position: absolute;
  top: 18px;
  width: 30px;
  height: 20px;
  padding-bottom: 5px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  .line {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: #767676;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2) {
      top: 10px;
    }
    &:nth-child(3) {
      top: 10px;
    }
    &:nth-child(4) {
      top: 20px;
    }
  }
}
.hamburger-menu.open .line:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.hamburger-menu.open .line:nth-child(2) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.hamburger-menu.open .line:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.hamburger-menu.open .line:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
.hidden {
  display: none;
}

.menu-item {
  background-color: transparent;
  color: #50963c;
  border: none;
  text-transform: capitalize;

  font-family: "RobotoRegular", sans-serif;
  &.mobile {
    font-weight: 600;
    padding: 12px 10px;
    &::after {
      content: "\00BB";
      padding-left: 4px;
    }
  }
}

.list-menu-item {
  &:first-child {
    border-top: 2px solid $primary;
  }
}

.tabs-list .tabs {
  @media screen and (max-width: 500px) {
    padding-left: 0;
  }
}

#menu- .MuiMenu-list {
  @media screen and (max-width: 500px) {
    padding-top: 0;
  }
}

a.menu-item.last-ele {
  margin-right: 0;
}

i.fa.fa-user {
  color: #50963c;
  border: 1px solid #50963c;
  padding: 0;
  font-size: 1.5rem;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: relative;
  &::before {
    position: relative;
    top: 5px;
  }
}

p.course-org-card,
p.course-date-card {
  font-size: 0.75rem;
}

.menu-user {
  text-decoration: none;
}

.banner-wrap {
  position: relative;
  div.MuiOutlinedInput-root {
    height: auto;
  }
  .banner-search {
    position: absolute;
    bottom: 10%;
    left: calc(50% - 200px);
    background-color: #fff;
    @media screen and (max-width: 1320px) {
      bottom: 10px;
    }
    @media screen and (max-width: 1024px) {
      position: static;
      text-align: left;
      top: 30px;
      width: 300px;
      margin-left: 30px;
    }
    input {
      min-width: 200px;
      min-height: 50px;
      @media screen and (max-width: 1100px) {
        height: 40px;
        min-height: auto;
      }
      @media screen and (max-width: 600px) {
        min-width: auto;
      }
    }
  }
  .search-icon {
    font-size: 2rem;
  }
}

.CookieConsent {
  padding: 12px;
  font-size: 13px;
  line-height: 1.4;
  width: 100% !important;
  > div {
    margin: 0 !important;
    p {
      padding: 8px 30px 15px 0px !important;
    }
  }
  a {
    color: #77bd7e;
  }
  #rcc-decline-button {
    padding: 10px 20px !important;
    color: #77bd7e !important;
    width: 110px;
    font-weight: bold;
    margin: 0 !important;
    background-color: #fff !important;
    border: 1px solid #77bd7e !important;
  }

  #rcc-confirm-button {
    padding: 10px 20px !important;
    color: #fff !important;
    width: 110px;
    font-weight: bold;
    margin: 0 !important;
    margin-left: 15px !important;
    margin-right: 30px !important;
    background-color: #77bd7e !important;
    border: 1px solid #77bd7e !important;

    &:hover {
    background-color: white !important;
    border: 1px solid #77bd7e !important;
    color: #77bd7e !important;
    }
    @media screen and (max-width: 991px) {
      margin-left: 0 !important;
    }
  }
}
