footer.App .grid-footer {
  padding: 20px;

  padding-top: 55px;
  background-color: #e9e9e9;
}

footer.App .grid-footer h1 {
  font-size: 1.5rem;
  text-align: left;
  padding-bottom: 25px;
}

footer.App .grid-footer ul {
  padding: 0;
  list-style: none;
}

footer.App .grid-footer li {
  padding-bottom: 20px;
}

footer.App .grid-footer p {
  line-height: 1.5;
}

footer.App .grid-footer a {
  font-size: 1.125rem;
  line-height: 1.25;
  color: #000;
  text-decoration: none;
}

footer.App .grid-footer .flex-items {
  display: flex;
  justify-content: right;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  footer.App .grid-footer .flex-items {
    justify-content: left;
  }
}
footer.App .grid-footer .flex-items li {
  display: flex;
  margin-right: 20px;
  &:last-child {
    margin-right: 0px;
  }
}

footer.App .grid-footer .flex-items li svg {
  color: white;
  background-color: #77bd7e;
  margin-left: 5px;
  border-radius: 50%;
}

footer.App .grid-footer hr {
  background-color: #77bd7e;
}

footer.App .footer-logo {
  margin-top: 20px;
  padding-right: 10px;
  padding-left: 15px;
  width: 160px;
  border-right: 1px solid #e9e9e9;
}

footer.App .flex-privacy_policy {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  footer.App .flex-privacy_policy {
    justify-content: left;
    padding: 0;
  }
}

footer.App .flex-privacy_policy li {
  list-style: none;
  padding-left: 15px;
  margin-bottom: 10px;
}

footer.App .flex-privacy_policy li a {
  color: #50963c;
  text-decoration: none;
}

footer.App div.tel {
  margin-left: 15px;
  margin-top: 40px;
}

footer.App div.tel svg {
  color: #77bd7e;
}
footer.App div.tel a {
  color: #000;
  position: relative;
  top: -5px;
  left: 10px;
  text-decoration: none;
}

footer.App .logo-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

ul.footer-list {
  max-width: 80%;
  li {
    line-height: 1.5;
  }
}
