$primary: #77bd7e;
body {
  margin: 0;
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  font-family: "RobotoRegular", sans-serif;
  font-size: 1rem;
}

h1 {
  font-family: "RobotoBold", sans-serif;
  @media screen and (max-width: 991px) {
    font-size: 2rem;
  }
}

h2 {
  font-family: "RobotoRegular", sans-serif;
  font-size: 2rem;
  font-weight: normal !important;
  margin: 0;
  color: $primary;
  @media screen and (max-width: 991px) {
    font-size: 1.5rem;
  }
}

h3 {
  font-family: "RobotoRegular", sans-serif;
  font-size: 1.5rem;
  font-weight: normal !important;
  @media screen and (max-width: 991px) {
    font-size: 1.25rem;
  }
}

h4 {
  font-family: "RobotoRegular", sans-serif;
  font-size: 1.25rem;
  @media screen and (max-width: 991px) {
    font-size: 1rem;
  }
}

button,
a,
ul,
li,
p,
select,
option {
  font-family: "RobotoRegular", sans-serif !important;
}
